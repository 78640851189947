/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useEffect, useRef } from 'react';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import '../styles/contact.css'
import '../styles/Cards.css'
import '../styles/HomeScreen.css'
import { toast } from 'react-toastify';
import emailjs from '@emailjs/browser';

const containerStyle = {
  width: '100%',
  height: '100%',
};

const fontSize = {
  fontsize: '13px',
}

const center = {
  lat: -34.397,
  lng: 150.644,
};

const contact = () => {
  const [formState, setFormState] = useState({
    name: '',
    email: '',
    message: '',
  });
  const handleFormChange = (event) => {
    const { name, value } = event.target;
    setFormState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    // TODO: inviare i dati del form al server
  };
  useEffect(() => {
    // TODO: ottenere la posizione dell'utente
  }, []);

  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_rcmilfn', 'template_oq9jy58', form.current, 'vh2gR_koNMqppxLw8')
    .then((result) => {
      console.log(result.text);
      toast.success(' Richiesta Effettuata!', {
        position: "top-right",
        autoClose: 3500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        });
    }, (error) => {
      console.log(error.text);
      toast.error('🦄 Wow so easy!', {
        position: "top-right",
        autoClose: 3500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        });
  });
  e.target.reset();
  };
  return (
    <>
      <header>
        <h1>Contatti</h1>
      </header>
      <main className="contact-container">
        <section className="contact-form">
          <h2>Invia un messaggio:</h2>
          <hr></hr> oppure chiama al: 085 4685704 <br></br> <hr></hr>Referente della struttura: Paolo Di Lorenzo - 339 6020710 <br></br> <hr></hr>
          <form  ref={form} onSubmit={sendEmail}>
            <label htmlFor="name">Nome:</label>
            <input
              type="text"
              id="name"
              name="user_name"
              // value={formState.name}
              // onChange={handleFormChange}
              required
            />
            <label htmlFor="email">Email:</label>
            <input
              type="email"
              id="email"
              name="user_email"
              // value={formState.email}
              // onChange={handleFormChange}
              required
            />
            <label htmlFor="message">Messaggio:</label>
            <textarea
              id="message"
              name="user_message"
              // value={formState.message}
              // onChange={handleFormChange}
              required
            ></textarea>
            <text>Inviando la richiesta di essere contattato accetto l'informativa privacy consultabile<a href='/privacy-sito'>QUI</a></text>
            <button type="submit" value="Invia richiesta">Invia Richiesta</button>
          </form>
        </section>
        <section className="peppa">
          <h2>Dove siamo</h2>
          <iframe
            width="850"
            height="750"
            //style="border:0"
            loading="lazy"
            //allowfullscreen
            referrerpolicy="no-referrer-when-downgrade"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2942.1941021195366!2d14.110033651307669!3d42.48742593493861!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1331a4f3133b3559%3A0xbff4ea35de24eee3!2sAgenzia%20Nazionale%20Sicurezza%20Sul%20Lavoro%20S.R.L.!5e0!3m2!1sit!2sit!4v1676471228393!5m2!1sit!2sit">
          </iframe>
        </section>
      </main>
    </>
    // <>
    // <div >
    //   <section className='section bg-white h-100'>
    //     <div id='containerCont'><br></br><br></br><br></br><br></br>
    //       <div id='fosin'>
    //         <form id='fosin'>
    //           <input type='text' name='nome' />
    //         </form>
    //       </div>
    //     </div>
    //   </section>
    // </div>
    //   <iframe
    //     width="450"
    //     height="750"
    //     //style="border:0"
    //     loading="lazy"
    //     //allowfullscreen
    //     referrerpolicy="no-referrer-when-downgrade"
    //     src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2942.1941021195366!2d14.110033651307669!3d42.48742593493861!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1331a4f3133b3559%3A0xbff4ea35de24eee3!2sAgenzia%20Nazionale%20Sicurezza%20Sul%20Lavoro%20S.R.L.!5e0!3m2!1sit!2sit!4v1676471228393!5m2!1sit!2sit">
    //   </iframe>
    //   </>
  )
}

export default contact