import React from 'react';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Navbar from './components/Navbar';
import home from './pages/home';
// import service from './pages/service';
import blog from './pages/blog';
import contact from './pages/contact';
import Footer from './components/Footer';
import ambiente from'./pages/ambiente';
import cantieristica from'./pages/cantieristica';
import cev from'./pages/cev';
import dirmacchine from'./pages/dirmacchine';
import facilityman from'./pages/facilityman';
import finanziamenti from'./pages/finanziamenti';
import formazione from'./pages/formazione';
import carrellielevatori from'./pages/corsiFormazione/carrelliElevatori';
import partielettriche from'./pages/corsiFormazione/partiElettriche';
import igienealimenti from'./pages/igienealimenti';
import pec from'./pages/pec';
import privacy from'./pages/privacy';
import servsanitari from'./pages/servsanitari';
import sicurezzaeventi from'./pages/sicurezzaeventi';
import sistemigestione from'./pages/sistemigestione';
import ssl from'./pages/ssl';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import privacyANSL from './pages/privacyANSL';
import prova from './pages/blogPages/prova';
import calendariocorsi from './pages/calendarioCorsi';
import fakepage from './pages/fakePage';
import preposti from './pages/corsiFormazione/preposti';
import blsd from './pages/corsiFormazione/blsd';
import patenteCrediti from './pages/blogPages/patenteCrediti';
import ddlLavoro from './pages/blogPages/ddlLavoro';
// import { useEffect } from 'react';
// import ReactGA from 'react-ga'

function App() {
  // const location = useLocation();

  // useEffect(() => {
  //   ReactGA.initialize('G-ZMZ7GEX4XP'); // sostituisci "UA-XXXXXXXXX-Y" con il tuo codice di tracciamento
  //   ReactGA.pageview(location.pathname + location.search);
  // }, [location]);
  return (
    <div className="bga">
      <Router>
        {/* <img src='../public/solo-occhio-2.png' alt='logo del sito' /> */}
        <Navbar/>
        <Switch>
          <Route path='/privacy-sito' exact component={privacyANSL} />
          <Route path='/' exact component={home} />
          <Route path='/ambiente' exact component={ambiente} />
          <Route path='/cantieristica' exact component={cantieristica} />
          <Route path='/controlli-e-verifiche' exact component={cev} />
          <Route path='/direttiva-macchine' exact component={dirmacchine} />
          <Route path='/facility-managment' exact component={facilityman} />
          <Route path='/finanziamenti' exact component={finanziamenti} />
          <Route path='/formazione' exact component={formazione} />
          <Route path='/igiene-degli-alimenti' exact component={igienealimenti} />
          <Route path='/progettazione-e-certificazione' exact component={pec} />
          <Route path='/privacy' exact component={privacy} />
          <Route path='/servizi-sanitari' exact component={servsanitari} />
          <Route path='/sicurezza-eventi' exact component={sicurezzaeventi} />
          <Route path='/sistemi-di-gestione' exact component={sistemigestione} />
          <Route path='/sicurezza-sul-lavoro' exact component={ssl} />
          <Route path='/contact' exact component={contact} />
          <Route path='/blog' exact component={blog} />
          <Route path='/prova' exact component={prova} />
          <Route path='/carrelli-elevatori' exact component={carrellielevatori} />
          <Route path='/parti-elettriche' exact component={partielettriche} />
          {/* <Route path='/calendario-corsi' exact component={calendariocorsi} /> */}
          <Route path='/in-costruzione' exact component={fakepage} />
          <Route path='/preposti' exact component={preposti} />
          <Route path='/blsd' exact component={blsd} />
          <Route path='/patente-e-crediti' exact component={patenteCrediti} />
          <Route path='/ddl-lavoro' exact component={ddlLavoro} />
      </Switch>
      <Footer/>
      </Router>
      <ToastContainer />
    </div>
  );
}

export default App;
