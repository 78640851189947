import React from 'react'

const ddlLavoro = () => {
  return (
    <>
        <div >
          <section className='section bg-white'>
            <div ><br></br><br></br>
                <h1 id='tit2'>DDL lavoro: salute e sicurezza sui luoghi di lavoro</h1>
                <br></br>
                <div style={{display:'flex',alignItems:'center', justifyContent:'center'}}>
                    <div style={{width: '80%', height: '80%', textAlign: 'center'}}>
                        <p style={{fontSize:'80%', textAlign: 'justify'}}>
                        <h style={{fontSize:'100%', fontWeight:'bold'}}>A partire dal 12 gennaio 2025</h>, entrerà in vigore la <h style={{fontSize:'100%', fontWeight:'bold'}}>legge 13 dicembre 2024, n. 203 </h>, che apporta <h style={{fontSize:'100%', fontWeight:'bold'}}>modifiche al Testo Unico sulla Sicurezza</h>, ovvero al D.lgs. n.81/2008.<br/>
                        L’obiettivo del Legislatore è ragionevolmente quello di semplificare e rendere più efficaci alcune procedure già disciplinate in materia di salute e sicurezza sui luoghi di lavoro, con l’intento sperato di rafforzare la protezione dei lavoratori e migliorare il controllo sulla sicurezza negli ambienti lavorativi. <br/>
                        Di seguito, vengono analizzate le principali novità introdotte dalla legge citata.<br/>
                        Le modifiche di maggior rilevanza sono indubbiamente quelle che attengono all’ambito della <h style={{fontSize:'100%', fontWeight:'bold'}}>sorveglianza sanitaria</h>, le quali meritano per tale ragione un focus specifico.

                        </p>
                        {/* <p style={{fontSize:'80%', textAlign: 'justify'}}>
                        Il preposto per adempiere efficacemente ai propri obblighi, elencati dall'art. 19 del D.lgs. 81/08, deve ricevere un'adeguata
                         e specifica formazione e relativo aggiornamento periodico così come previsto dall'art. 37 del D.lgs. 81/08.
                        </p> */}
                    </div>
                </div>
                <br></br>
                <div style={{display:'flex', alignItems: 'center', width: '100%', height: '100%', }}>    
                    <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', textAlign:'left', width: '100%', height: '100%', overflow: 'auto'}}>
                        <div style={{display: 'flex', alignItems: 'center' , justifyContent:'center'}}>
                            <div style={{display: 'flex', flexDirection: 'column', textAlign:'left', width: '80%', height: '80%', overflow: 'auto'}}>
                                <h6 style={{color: '#035394', fontSize:'90%', fontWeight:'bold'}}>VISITA MEDICA PREVENTIVA IN FASE PREASSUNTIVA</h6>
                                <p style={{width: '100%', wordWrap: 'break-word', fontSize:'80%', textAlign: 'justify'}}>
                                Una delle novità introdotte è relativa alla modalità di svolgimento della <h style={{fontSize:'100%', fontWeight:'bold'}}>visita medica preventiva in fase preassuntiva</h>, ovvero della visita medica che il datore di lavoro può decidere di svolgere nei confronti di un lavoratore prima dell’effettiva e formale instaurazione del rapporto di lavoro, ovvero prima della sottoscrizione del contratto.<br/><br/>
                                A far data dal prossimo 12 gennaio tale tipologia di visita <h style={{fontSize:'100%', fontWeight:'bold'}}>potrà essere effettuata dal datore di lavoro per il solo tramite del Medico competente</h> e non più anche, in alternativa a quest’ultimo, dai dipartimenti di prevenzione delle ASL, come previsto dalla normativa attualmente in vigore. <br/><br/>
                                La nuova previsione legislativa è dunque volta ad escludere la scelta oggi rimessa al datore di lavoro, imponendo a quest’ultimo di rivolgersi al professionista sanitario nominato. <br/><br/>
                                La finalità è ovviamente quella di agevolare e snellire tale adempimento, accentrando su una unica figura professionale la competenza a procedere.
                                </p>
                                </div>
                                </div>
                            </div>
                </div>
                <br/>
                <div style={{display:'flex', alignItems: 'center', width: '100%', height: '100%',}}>    
                    <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', textAlign:'left', width: '100%', height: '100%', overflow: 'auto'}}>
                        <div style={{display: 'flex', alignItems: 'center' , justifyContent:'center'}}>
                            <div style={{display: 'flex', flexDirection: 'column', textAlign:'left', width: '80%', height: '80%', overflow: 'auto'}}>
                                <h6 style={{color: '#035394', fontSize:'90%', fontWeight:'bold'}}>RICORSI AVVERSO I GIUDIZI DI IDONEITÀ EMESSI DAL MEDICO COMPETENTE </h6>
                                <p style={{width: '100%', wordWrap: 'break-word', fontSize:'80%', textAlign: 'justify'}}>
                                Nella medesima ottica di semplificazione, il legislatore ha poi optato per <h style={{fontSize:'100%', fontWeight:'bold'}}>l’espressa ed esatta individuazione dell’organo territorialmente competente ad esprimersi sui ricorsi aventi ad oggetto i giudizi emessi dal Medico competente</h>.
                                Come noto, il Testo Unico sulla Sicurezza stabilisce che, al termine di ogni visita svolta dal Medico competente ai fini della sorveglianza sanitaria, una copia del giudizio emesso dal professionista debba essere sempre consegnata al lavoratore e al datore di lavoro, qualunque sia la valutazione espressa dal sanitario.
                                Avverso i giudizi emessi dal Medico competente, compresi quelli formulati in fase preassuntiva, è ammesso ricorso, entro 30 giorni dalla data di comunicazione del giudizio medesimo, all’organo di vigilanza territorialmente competente che dispone, dopo eventuali ulteriori accertamenti, la conferma, la modifica o la revoca del giudizio stesso.
                                L’attuale formulazione dell’art. 41, comma 9, D.lgs. n. 81/2008 non individua, dunque, in maniera inequivocabile il soggetto competente a pronunciarsi sui suddetti ricorsi.
                                La legge n. 203/2024 è intervenuta su tale punto, prevedendo che <h style={{fontSize:'100%', fontWeight:'bold'}}>l’organo deputato a pronunciarsi in tali casi sia l’Azienda sanitaria competente</h>, fugando così ogni dubbio sulla questione.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>     
                <br/>
                <div style={{display:'flex', alignItems: 'center', width: '100%', height: '100%',}}>    
                    <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', textAlign:'left', width: '100%', height: '100%', overflow: 'auto'}}>
                        <div style={{display: 'flex', alignItems: 'center' , justifyContent:'center'}}>
                            <div style={{display: 'flex', flexDirection: 'column', textAlign:'left', width: '80%', height: '80%', overflow: 'auto'}}>
                                <h6 style={{color: '#035394', fontSize:'90%', fontWeight:'bold'}}>IL GIUDIZIO DI IDONEITÀ ALLA MANSIONE NELL’IPOTESI DI MALATTIA SUPERIORE A 60 GIORNI CONSECUTIVI</h6>
                                <p style={{width: '100%', wordWrap: 'break-word', fontSize:'80%', textAlign: 'justify'}}>
                                Il D.lgs. n. 81/2008, ad oggi, prevede che nell’ipotesi in cui un lavoratore si assenti dal luogo di lavoro per motivi di salute per un periodo superiore a 60 giorni consecutivi, il Medico competente è tenuto ad esprimersi sull’idoneità alla mansione del lavoratore alla ripresa del lavoro da parte di quest’ultimo, per mezzo di apposita visita medica.<br/><br/>
                                La legge n. 203/2024, a tal riguardo, concede al Medico competente la <h style={{fontSize:'100%', fontWeight:'bold'}}>facoltà di esprimersi circa l’idoneità alla mansione del lavoratore dopo aver proceduto a svolgere apposita vista medica o, diversamente, in assenza di essa.</h>
                                Si sottolinea, tuttavia, come tale nuova previsione mal si concili, facendo dunque sorgere qualche perplessità, con la disposizione contenuta nell’art. 41, comma 6 bis, D.lgs. n. 81/2008, laddove è previsto che il Medico competente esprima il proprio giudizio di idoneità sulla base delle risultanze della visita medica svolta. 
                                A tal proposito, dunque, in presenza di un giudizio di idoneità in corso di validità al momento del rientro, può ritenersi sufficiente una dichiarazione rilasciata e sottoscritta dal professionista sanitario, che riporti sinteticamente le motivazioni che hanno determinato lo stesso a non procedere con apposita visita medica e contestuale conferma del giudizio di idoneità del lavoratore emesso in precedenza.
                                Diversamente, in caso di giudizio di idoneità del lavoratore scaduto o prossimo alla scadenza, si reputa necessario procedere a nuova visita al momento del rientro al lavoro.
                                Ad ogni buon conto, si può senz’altro apprezzare la portata della novità introdotta, tenuto conto del fatto che in alcuni casi la visita medica al rientro da una assenza prolungata può considerarsi superflua in considerazione della causa che ha portato all’assenza del lavoratore e la mansione effettivamente svolta.

                                </p>
                            </div>
                        </div>
                    </div>
                </div>     
                <br/>
                <div style={{display:'flex', alignItems: 'center', width: '100%', height: '100%',}}>    
                    <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', textAlign:'left', width: '100%', height: '100%', overflow: 'auto'}}>
                        <div style={{display: 'flex', alignItems: 'center' , justifyContent:'center'}}>
                            <div style={{display: 'flex', flexDirection: 'column', textAlign:'left', width: '80%', height: '80%', overflow: 'auto'}}>
                                <h6 style={{color: '#035394', fontSize:'90%', fontWeight:'bold'}}>ESAMI CLINICI E BIOLOGICI  </h6>
                                <p style={{width: '100%', wordWrap: 'break-word', fontSize:'80%', textAlign: 'justify'}}>
                                Una ulteriore novità introdotta con l’art. 1 della citata legge attiene alla <h style={{fontSize:'100%', fontWeight:'bold'}}>facoltà in capo al Medico competente di tener conto, nella prescrizione di esami clinici e biologici e di indagini diagnostiche ritenuti necessari in sede di visita preventiva, delle risultanze dei medesimi esami ed indagini già effettuati dal lavoratore e risultanti dalla copia della cartella sanitaria e di rischio in possesso del lavoratore medesimo</h> ai fini di evitarne la ripetizione, qualora il professionista sanitario ritenga gli stessi compatibili con la finalità della visita preventiva.<br /><br/>
                                Evidentemente l’obiettivo perseguito dal legislatore è quello di evitare inutili e dispendiose ripetizioni di esami medici, migliorando l’efficienza complessiva del sistema di sorveglianza sanitaria.<br/>
                                Si precisa, ad ogni modo, che tale previsione si sostanzia essenzialmente nella formale legittimazione di una prassi già diffusamente praticata dai Medici competenti.  
                                </p>
                            </div>
                        </div>
                    </div>
                </div>      
                <br/>  
                <div style={{display:'flex', alignItems: 'center', width: '100%', height: '100%',}}>    
                    <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', textAlign:'left', width: '100%', height: '100%', overflow: 'auto'}}>
                        <div style={{display: 'flex', alignItems: 'center' , justifyContent:'center'}}>
                            <div style={{display: 'flex', flexDirection: 'column', textAlign:'left', width: '80%', height: '80%', overflow: 'auto'}}>
                                <h6 style={{color: '#035394', fontSize:'90%', fontWeight:'bold'}}>AGGIORNAMENTO PROFESSIONALE DEI MEDICI COMPETENTI</h6>
                                <p style={{width: '100%', wordWrap: 'break-word', fontSize:'80%', textAlign: 'justify'}}>
                                Restando in materia di sorveglianza sanitaria, la legge n. 203/2024 attribuisce, infine, al Ministero della Salute il compito di monitorare e dunque verificare periodicamente la partecipazione dei Medici competenti al programma di educazione continua in medicina, come previsto dall’art. 38, comma 3, D.lgs. n. 81/2008, finalizzata alla permanenza dell’iscrizione all’elenco dei Medici competenti istituito presso il Ministero del lavoro.
                                Prescindendo, poi, dall’ambito della sorveglianza sanitaria, la legge in esame introduce ulteriori novità in materia di salute e sicurezza sui luoghi di lavoro. 
                                </p>
                            </div>
                        </div>
                    </div>
                </div>    
                <br/> 
                <div style={{display:'flex', alignItems: 'center', width: '100%', height: '100%',}}>    
                    <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', textAlign:'left', width: '100%', height: '100%', overflow: 'auto'}}>
                        <div style={{display: 'flex', alignItems: 'center' , justifyContent:'center'}}>
                            <div style={{display: 'flex', flexDirection: 'column', textAlign:'left', width: '80%', height: '80%', overflow: 'auto'}}>
                                <h6 style={{color: '#035394', fontSize:'90%', fontWeight:'bold'}}>USO DEI LOCALI SOTTERRANEI PER LE LAVORAZIONI </h6>
                                <p style={{width: '100%', wordWrap: 'break-word', fontSize:'80%', textAlign: 'justify'}}>
                                La legge n. 203/2024 è altresì intervenuta con la modifica dell’art. 65 del D.lgs. n. 81/2008 che disciplina <h style={{fontSize:'100%', fontWeight:'bold'}}>l’uso dei locali sotterranei o semisotterranei per le lavorazioni</h>.<br/>
                                Viene meno il riferimento alle “particolari esigenze tecniche”, che costituiscono oggi il presupposto per l’uso di tali locali.<br/>
                                Resta fermo, invece, il <h style={{fontSize:'100%', fontWeight:'bold'}}>divieto di emissioni di agenti nocivi</h>, sempre che siano rispettati i <h style={{fontSize:'100%', fontWeight:'bold'}}>requisiti di cui all’Allegato IV del D.lgs. n. 81/2008</h> in quanto applicabili, ed il <h style={{fontSize:'100%', fontWeight:'bold'}}>rispetto delle idonee condizioni di aerazione, illuminazione e microclima</h>.<br/><br/>
                                La nuova disciplina regolamenta nel dettaglio anche la procedura da seguire per l’utilizzo dei suddetti locali. In particolare, il datore di lavoro sarà tenuto a comunicare al competente ufficio territoriale dell’Ispettorato nazionale del Lavoro, a mezzo di posta elettronica certificata, l’uso dei locali sotterranei o semisottarranei, allegando adeguata documentazione, individuata con apposita circolare dell'Ispettorato stesso, che dimostri il rispetto dei requisiti di cui si è detto sopra.<br/>
                                Il datore di lavoro sarà così legittimato ad utilizzare i locali, trascorsi 30 giorni dalla data della comunicazione effettuata.<br/>
                                Qualora l’ufficio dell’Ispettorato territorialmente competente richieda ulteriori informazioni, l’uso dei locali è consentito trascorsi 30 giorni dalla comunicazione delle ulteriori informazioni richieste, salvo espresso divieto da parte dell’ufficio medesimo.<br/>
                                Per l’effettiva applicazione di tale nuova previsione sarà dunque necessario attendere l’emanazione di una specifica circolare da parte dell’Ispettorato nazionale del lavoro, concernente l’individuazione della documentazione utile a dimostrare il rispetto dei requisiti di cui all’Allegato IV del D.lgs. n. 81/2008, come precisato nella nota n. 9740 del 30 dicembre 2024 dell’INL.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>      
                <br/>     
                <div style={{display:'flex', alignItems: 'center', width: '100%', height: '100%',}}>    
                    <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', textAlign:'left', width: '100%', height: '100%', overflow: 'auto'}}>
                        <div style={{display: 'flex', alignItems: 'center' , justifyContent:'center'}}>
                            <div style={{display: 'flex', flexDirection: 'column', textAlign:'left', width: '80%', height: '80%', overflow: 'auto'}}>
                                <h6 style={{color: '#035394', fontSize:'90%', fontWeight:'bold'}}>COMUNICAZIONE ISTITUZIONALE ANNUALE SULLA SICUREZZA NEI LUOGHI DI LAVORO</h6>
                                <p style={{width: '100%', wordWrap: 'break-word', fontSize:'80%', textAlign: 'justify'}}>
                                Tra le novità introdotte dalla legge n. 203/2024 vi è, infine, la previsione di una comunicazione da rendere da parte del Ministro del lavoro e delle politiche sociali alle Camere,  da effettuare entro il 30 aprile di ogni anno, avente ad oggetto lo stato della sicurezza nei luoghi  di  lavoro,  con riferimento all'anno precedente, nonché gli interventi da adottare per migliorare le condizioni di salute e di sicurezza nei  luoghi  di lavoro e gli orientamenti e i programmi legislativi che il  Governo intende adottare al riguardo per l'anno in corso.<br/>
                                L’introduzione di questo strumento che potremmo definire “di rendicontazione” mira a migliorare la trasparenza del sistema di comunicazione istituzionale, stimolando un continuo aggiornamento dello stato e dell’efficacia delle politiche di sicurezza sul lavoro adottate.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <br/>
                <div style={{display:'flex', alignItems: 'center', width: '100%', height: '100%',}}>    
                    <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', textAlign:'left', width: '100%', height: '100%', overflow: 'auto'}}>
                        <div style={{display: 'flex', alignItems: 'center' , justifyContent:'center'}}>
                            <div style={{display: 'flex', flexDirection: 'column', textAlign:'left', width: '80%', height: '80%', overflow: 'auto'}}>
                                <h6 style={{color: '#035394', fontSize:'90%', fontWeight:'bold'}}>CONCLUSIONI</h6>
                                <p style={{width: '100%', wordWrap: 'break-word', fontSize:'80%', textAlign: 'justify'}}>
                                Le modifiche introdotte dalla legge 13 dicembre 2024, n. 203, segnano un nuovo passo in avanti nell'evoluzione della normativa in materia di salute e sicurezza nei luoghi di lavoro.<br/><br/>
                                L'intento di semplificare le procedure, come evidenziato in particolare dalla razionalizzazione degli adempimenti legati alla sorveglianza sanitaria, e quello di diradare dubbi di natura interpretativa delle norme inserite nel Testo Unico, si affiancano alla volontà di garantire maggiore efficacia a livello di prevenzione. <br/><br/>
                                Il successo di queste modifiche dipenderà, tuttavia, anche dalla capacità di imprese e professionisti di adattarsi in modo proattivo e informato, garantendo la piena attuazione degli obiettivi di miglioramento della sicurezza e della salute dei lavoratori.                      
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
          </section>
        </div>
        </>
  )
}

export default ddlLavoro